import { useEffect, useState } from 'react';
import { showCustomModal } from '@atoms/ACustomModalInfo';
import { ArrowLeft } from '@carespace-icons/arrows/arrowLeft';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { Button, message, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from '@carespace-icons/arrows/chevronDown';
import { Target04 } from '@carespace-icons/general/target04';
import { Calendar } from '@carespace-icons/time/calendar';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACTIVETAB } from '@stores/constants';
import { createProgram } from '@stores/aiAssistant/program';
import { IProgramExercise } from '@stores/interfaces';

interface ISelectedProgram {
  exercises?: {
    strapiExerciseId: {
      exercise_image: {
        url: string;
      }[];
      exercise_video: {
        url: string;
      }[];
      name: string;
      description: string;
    };
    weeklyReps: string;
    dailyReps: string;
    sets: string;
    reps: string;
  }[];
  functional_goals?: {
    name: string;
  }[];
  duration?: string;
  durationType?: string;
  name?: string;
  description?: string;
  thumbnail: {
    url?: string
  }
}


const SelectedProgram = () => {
  const { t } = useTranslation();
  const [showAllExercises, setShowAllExercises] = useState(false);
  const dispatch = useTypedDispatch()
  const state = useLocation();
  const navigate = useNavigate();
  const activeTab = useTypedSelector(
    state => state.aiAssistant.aiAssistant.activeTab,
  );
  const user = useTypedSelector((state) => state.user)

  const handleEnroll = async (item: any) => {
    let exercises = [];
    item?.exercises?.forEach(
      (exercise: IProgramExercise, index: number) => {
        const tempExercise = {
          strapiExerciseId: exercise?.strapiExerciseId?.id || exercise?.strapiExerciseId || '',
          exerciseLibraryId: exercise.exerciseLibraryId || '',
          weeklyReps: exercise.weeklyReps,
          dailyReps: exercise.dailyReps,
          sets: exercise.sets,
          reps: exercise.reps,
          order: index + 1,
        };
        exercises.push(tempExercise);
      },
    );
    const formData = new FormData();
    formData.append('name', item?.name);
    formData.append('duration', item?.duration);
    formData.append('durationType', item?.durationType);
    formData.append('description', item?.description);
    formData.append('thumbnail', item?.thumbnail?.url || item?.thumbnail);
    if (user?.id) {
      formData.append('userId', String(user?.id));
    } else {
      console.error('userId is missing or invalid');
      return;
    }
    formData.append('strapiProgramTemplateId', item?.id);
    exercises?.forEach((exercise, index) => {
      Object.keys(exercise).forEach(key => {
        if (exercise[key]) {
          formData.append(
            `exercises[create][${index}][${key}]`,
            exercise[key],
          );
        }
      });
    });
    const data = await dispatch(createProgram(formData));
    data?.payload?.id && message.success(t('Patient.data.newDashboard.enrolled'))
  }

  useEffect(() => {
    activeTab != ACTIVETAB.HOME && dispatch(setActiveTab(ACTIVETAB.HOME))
  }, [activeTab])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const buttonStyle = {
    color: '#533F85',
    border: 'inherit',
    width: '200px',
    height: "50px",
    marginTop: '6px',
    marginBottom: '6px'
  };

  const exercisesToShow = showAllExercises ? state?.state?.selectedProgram?.exercises : state?.state?.selectedProgram?.exercises?.slice(0, 5);

  const popoverContent = (
    <div>
      <div className="text-left">
        <h3 style={{ fontSize: '1rem' }}>
          <Target04 width={17} height={17} color="stroke-gray-700" /> {t('Patient.data.newDashboard.functionalGoals')}
        </h3>
      </div>
      <hr style={{ margin: '8px 0' }} />
      {state?.state?.selectedProgram?.functional_goals?.map((goal: { name: string }) => (
        <li key={goal.name} className="font-sans border-2 border-white pt-1 pb-1 pl-4 pr-4 rounded-full text-sm font-bold leading-5">{goal?.name}</li>))}
    </div>
  );

  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="relative h-[500px] w-full zoom-animation">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <img
            src={state?.state?.selectedProgram?.thumbnail?.url || state?.state?.selectedProgram?.exercises && state?.state?.selectedProgram?.exercises[0]?.strapiExerciseId?.exercise_image[0]?.url}
            alt={state?.state?.selectedProgram?.name}
            className={`h-[500px] w-full ${state?.state?.selectedProgram?.thumbnail ? "object-cover" : "object-contain"
              } `}
          />
        </div>
        <div className="absolute top-5 left-5 z-10 rounded-full cursor-pointer p-2" onClick={() => navigate(-1)} style={{backgroundColor:'var(--mainmenu-bg-color'}}>
          <ArrowLeft color='stroke-white' />
        </div>
        <div className="absolute bottom-0 left-0 z-10 text-white p-5">
          <div className="flex gap-4">
            <p className="font-sans pt-1 rounded-full text-sm font-bold leading-5 flex items-center justify-center gap-2">
              <Calendar />
              {state?.state?.selectedProgram?.duration}{" "}
              {state?.state?.selectedProgram?.durationType
                ? state?.state?.selectedProgram.duration > 1
                  ? state?.state?.selectedProgram.durationType.charAt(0).toUpperCase() + state?.state?.selectedProgram.durationType.slice(1)
                  : state?.state?.selectedProgram.durationType.charAt(0).toUpperCase() + state?.state?.selectedProgram.durationType.slice(1).replace(/s$/, "")
                : ""}
            </p>
          </div>
          <div className='mb-4'>
            <p className="font-inter text-[60px] font-bold  tracking-[-0.02em] text-left text-white">{state?.state?.selectedProgram?.name}</p>
            <div className="flex gap-4">
              {state?.state?.selectedProgram?.functional_goals && state?.state?.selectedProgram?.functional_goals[0] && <p className="font-sans border-2 border-white pt-1 pb-1 pl-4 pr-4 rounded-full text-sm font-bold leading-5 text-center">{state?.state?.selectedProgram?.functional_goals && state?.state?.selectedProgram?.functional_goals[0]?.name}</p>}
              {state?.state?.selectedProgram?.functional_goals && state?.state?.selectedProgram?.functional_goals[1] && <p className="font-sans border-2 border-white pt-1 pb-1 pl-4 pr-4 rounded-full text-sm font-bold leading-5 text-center">{state?.state?.selectedProgram?.functional_goals && state?.state?.selectedProgram?.functional_goals[1]?.name}</p>}
              {state?.state?.selectedProgram?.functional_goals?.length! > 2 &&
                <Popover content={popoverContent} trigger="hover" placement="topLeft" >
                  <p className="font-sans border-2 border-white pt-1 pb-1 pl-4 pr-4 rounded-full text-sm font-bold leading-5 flex items-center cursor-pointer">
                    {t('Patient.data.newDashboard.showAll')}
                  </p>
                </Popover>}
            </div>
          </div>
          <Popover
            content={state?.state?.selectedProgram?.description}
            trigger="hover"
            placement='topLeft'
            overlayInnerStyle={{
              maxWidth: "50vw",
              whiteSpace: "normal",
            }}
            overlayStyle={{
              overflowX: "hidden",
            }}
            autoAdjustOverflow={true}
          >
            <p
              className="font-sans cursor-pointer text-base font-normal leading-6 text-left text-gray-300 w-[50%] mb-4 line-clamp-2"
            >
              {state?.state?.selectedProgram?.description}
            </p></Popover>
          <Button
            className="start-session-css"
            style={buttonStyle}
            onClick={(e) => {
              e.stopPropagation();
              handleEnroll(state?.state?.selectedProgram)
            }}
          >
            <span className='font-sans text-[22px] font-bold leading-[38px] text-left' style={{color:'var(--button-text-color)'}}>{t('Patient.data.newDashboard.enroll')}</span>
          </Button>
        </div>
      </div>
      <div className='p-5'>
        <p className='font-inter text-xl font-bold leading-[30px] text-left text-gray-700'>{state?.state?.selectedProgram?.exercises?.length > 1 ? t('Patient.data.newDashboard.exercises') : t('Patient.data.newDashboard.exercise')}</p>
      </div>
      <div className='p-5'>
        {exercisesToShow?.map((item: { strapiExerciseId: { exercise_image: { url: string; }[]; exercise_video: { url: string; }[]; name: string; description: string; }; weeklyReps: string; dailyReps: string; sets: string; reps: string }, index: number) => (
          <div key={index}>
            <div className='flex mt-2 mb-2'>
              <div className='flex items-center justify-center'>
                <p className='w-[50px] flex items-center justify-center font-sans font-semibold text-[18px] text-[#6B6B6B]'>{index + 1}</p>
              </div>
              <div className='w-[100px] mr-5'>
                <div className='h-[100px] w-[100px] overflow-hidden'>
                  <div
                    onClick={() =>
                      showCustomModal({
                        video: item?.strapiExerciseId?.exercise_video[0]?.url,
                        name: item?.strapiExerciseId?.name,
                        description: item?.strapiExerciseId?.description,
                      })
                    }
                    className="w-full h-full rounded-lg relative image-wrapper-exercises cursor-pointer">
                    <img
                      src={item?.strapiExerciseId?.exercise_image[0]?.url}
                      alt={item?.strapiExerciseId?.name}
                      className='w-full h-full object-cover'
                    />
                    <div className="play-button-exercises">
                      <PlayCircle width={50} height={50} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col justify-center'>
                <p className='font-inter text-lg font-bold leading-[21.78px] text-left  text-primary-800'>{item?.strapiExerciseId?.name}</p>
                <p className='font-inter text-sm font-normal leading-[16.94px] text-left text-[#969696]'>{t('Patient.data.rehab.weekly').charAt(0).toUpperCase()}{t('Patient.data.rehab.weekly').slice(1).toLowerCase()}{' '}
                  {item?.weeklyReps}X{' '}
                  {t('Patient.data.rehab.daily').charAt(0).toUpperCase()}{t('Patient.data.rehab.daily').slice(1).toLowerCase()}{' '}
                  {item?.dailyReps}X{' '}
                  {t('Patient.data.rehab.sets').charAt(0).toUpperCase()}{t('Patient.data.rehab.sets').slice(1).toLowerCase()}{' '}
                  {item?.sets}X{' '}
                  {t('Patient.data.rehab.reps').charAt(0).toUpperCase()}{t('Patient.data.rehab.reps').slice(1).toLowerCase()}{' '}
                  {item?.reps}X</p>
                <p className='font-inter text-sm font-normal leading-[16.94px] text-left text-gray-600 mt-2 w-[80%]'>{item?.strapiExerciseId?.description}</p>
              </div>
            </div>
            <hr />
          </div>
        ))}
        {!showAllExercises && state?.state?.selectedProgram?.exercises?.length! > 5 && (
          <div className="flex justify-center mt-4">
            <button
              className="bg-black rounded-full flex border-2 border-gray-400 cursor-pointer items-center p-2 gap-2"
              onClick={() => setShowAllExercises(true)}
            >
              <ChevronDown />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedProgram;
