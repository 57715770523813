import { useEffect, useState } from 'react';
import { Table, Tag, Popover, Tooltip, Flex } from 'antd';
import BodySilhouetteWithHotspots from './HotspotBodyJointfs';
import { useTypedSelector } from '@stores/index';
import { IRomPatientResult } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';

const CombinedMobilityReport = () => {
	const { t } = useTranslation();
	const getColorForCategory = (score: number) => {
		if (score >= 90) return '#52c41a';
		if (score >= 75) return '#52c41a';
		if (score >= 60) return '#faad14';
		if (score >= 40) return '#d9363e';
		if (score >= 20) return '#d9363e';
		return '#d9363e';
	};

	const getCategoryName = (score: number) => {
		if (score >= 90) return t('Patient.data.omnirom.optimalMobility');
		if (score >= 75) return t('Patient.data.omnirom.functionalMobility');
		if (score >= 60) return t('Patient.data.omnirom.moderateRestriction');
		if (score >= 40) return t('Patient.data.omnirom.limitedMobility');
		if (score >= 20) return t('Patient.data.omnirom.restrictedMobility');
		return t('Patient.data.omnirom.criticallyImpaired');
	};

	const getCategoryDesc = (score: number) => {
		if (score >= 90) return t('Patient.data.omnirom.optimalMobilityDesc');
		if (score >= 75) return t('Patient.data.omnirom.functionalMobilityDesc');
		if (score >= 60) return t('Patient.data.omnirom.moderateRestrictionDesc');
		if (score >= 40) return t('Patient.data.omnirom.limitedMobilityDesc');
		if (score >= 20) return t('Patient.data.omnirom.restrictedMobilityDesc');
		return t('Patient.data.omnirom.criticallyImpairedDesc');
	};

	const [rightData, setRightData] = useState<IRomPatientResult[]>();
	const [leftData, setLeftData] = useState<IRomPatientResult[]>();
	const { selectedRom } = useTypedSelector(state => state.rom.customRom);

	useEffect(() => {
		const left: IRomPatientResult[] = [];
		const right: IRomPatientResult[] = [];

		selectedRom?.romPatientResults?.forEach(item =>
			item?.results?.forEach(result => {
				const name: string = result?.romProgramExercise?.strapiOmniRomExercise?.name ||
					result?.romProgramExercise?.exerciseLibrary?.title ||
					t('Patient.data.omnirom.noTitle')
				name?.toLowerCase()?.includes('left')
					? left.push({
						...result,
						title: name,
					})
					: right.push({
						...result,
						title: name,
					});
			}),
		);

		setLeftData(left);
		setRightData(right);
	}, [selectedRom]);

	const tableColumns = [
		{
			title: t('Patient.data.omnirom.joint'),
			dataIndex: 'title',
			key: 'title',
			render: (text: string) => (
				<div
					style={{
						width: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}>
					{text?.replace(/(Right|Left)/g, '')}
				</div>
			),
		},
		{
			title: 'R',
			dataIndex: 'right',
			key: 'right',
			className: 'custom-header-align',
			render: (_: string, record: IRomPatientResult) => (
				<Tooltip
					title={getCategoryName(record.value)}
					color={getColorForCategory(record.score ? record.score : record.value)}>
					<Flex align="space-between" gap="small">
						<Tag
							color={getColorForCategory(record.score ? record.score : record.value)}
							style={{
								marginInlineEnd: 0,
								width: '35px',
								textAlign: 'center',
							}}>
							{record.value || '-'}
						</Tag>
					</Flex>
				</Tooltip>
			),
		},
	];

	const tableColumns2 = [
		{
			title: t('Patient.data.omnirom.joint'),
			dataIndex: 'title',
			key: 'title',
			render: (text: string) => (
				<div
					style={{
						width: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}>
					{text?.replace(/(Right|Left)/g, '')}
				</div>
			),
		},
		{
			title: <span style={{ paddingLeft: '12px' }}>L</span>,
			dataIndex: 'left',
			key: 'left',
			className: 'custom-header-align-right',
			with: '100%',
			render: (_: string, record: IRomPatientResult) => (
				<Popover
					content={
						<span
							style={{
								color: getColorForCategory(record.score ? record.score : record.value),
								fontWeight: 'bold',
							}}>
							{getCategoryName(record.value)}
						</span>
					}>
					<Tag
						color={getColorForCategory(record.score ? record.score : record.value)}
						style={{ marginInlineEnd: 0, textAlign: 'center', width: '35px' }}>
						{record.value || '-'}
					</Tag>
				</Popover>
			),
		},
	];

	return (
		<div
			style={{ height: '100%', background: '#ffffff', borderRadius: '12px' }}>
			<Flex style={{ height: '100%', justifyContent: 'space-between' }}>
				<Table
					className="rounded-table-left"
					size="small"
					tableLayout="auto"
					dataSource={rightData}
					columns={tableColumns}
					pagination={false}
					rowKey="joint"
				/>
				<BodySilhouetteWithHotspots
					getColorForCategory={getColorForCategory}
					getCategoryName={getCategoryName}
					getCategoryDesc={getCategoryDesc}
				/>
				<Table
					className="rounded-table-right flex"
					size="small"
					tableLayout="auto"
					dataSource={leftData}
					columns={tableColumns2}
					pagination={false}
					rowKey="joint"
					style={{ width: '34%' }}
				/>
			</Flex>
		</div>
	);
};

export default CombinedMobilityReport;
