import { Plus } from "@carespace-icons/general/plus"
import { FaceSad } from "@carespace-icons/users/faceSad"
import { IProgramData, IProgramExercise } from "@stores/interfaces"
import { Collapse } from "antd"
import { ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AddProgramPopup } from "./AddProgramPopup"
import { MagicWand01 } from "@carespace-icons/editor/magicWand01"
import { GrYoga } from "react-icons/gr"
import { showCustomModal } from "@atoms/ACustomModalInfo"
import { PlayCircle } from "@carespace-icons/media/playCircle"
import { useNavigate } from "react-router-dom"
import Button from "@atoms/Button"
import { ArrowRight } from "@carespace-icons/arrows/arrowRight"

const { Panel } = Collapse

interface ExerciseProps {
  searchValue?: string
  setSearchValue: (value: string) => void
  extraContent: (data: IProgramData) => ReactNode
  fetchData: (pageNumber: number) => void
  fetchOpenAiProgram: () => void
  refresh: boolean
  setRefresh: (refresh: boolean) => void
  program: {
    data: IProgramData | null,
    errorMessage: string,
    statusCode: number
  }
}

export const AiModalData = (props: ExerciseProps) => {
  const { extraContent, fetchData, fetchOpenAiProgram, refresh, setRefresh, program } = props

  const { t } = useTranslation()
  const [selectedProgram, setSelectedProgram] = useState<IProgramData | null>(program?.data)
  const [isModalVisible, setModalVisible] = useState(false)
  const navigate = useNavigate()
  useEffect(() => setSelectedProgram(program?.data), [program])

  const updateSelectedProgramExercises = (exerciseList: IProgramExercise[]) => {
    setSelectedProgram((prevState) => {
      return prevState ? { ...prevState, exercises: exerciseList } : prevState;
    });
  }

  return (
    <>
      {program?.data
        ? <><Collapse defaultActiveKey={program?.data?.id}
          style={{ maxHeight: "70vh", overflowX: "hidden", overflowY: "auto" }}
          className="select-none p-4 rounded-md bg-gray-50 hover:!bg-gray-50 flex flex-col gap-2" bordered={false}>
          <Panel
            className='header-panel !w-full bg-white !border !border-gray-200 !rounded-xl'
            key={program?.data?.id}
            header={<p className="text-sm font-semibold text-gray-600">{program?.data?.name}</p>}
            extra={extraContent(program?.data)}
          >
            {
              program?.data?.exercises?.map((item) => {
                return (
                  <div className="px-4 py-2">
                    <div className='flex cursor-pointer gap-4 p-4 rounded-lg border-2 border-inherit w-full' key={item?.name}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          showCustomModal({
                            video: item?.video,
                            name: item?.name,
                            description: item?.description,
                          })
                        }}
                        className={`custom-image-container`}>
                        <div className="w-40 flex-shrink-0 image-wrapper">
                          <img src={item?.image} alt="" className="rounded-lg w-full h-full" />
                          <div className="play-button">
                            <PlayCircle width={50} height={50} />
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 rounded-lg p-2">
                        <p className="text-gray-600 font-semibold text-lg">{item?.name}</p>
                        <p className="text-gray-400 font-semibold text-xs">{t('Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.weekly')} {item?.weeklyReps}X     {t('Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.daily')} {item?.dailyReps}X    {t('Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.sets')} {item?.sets}X     {t('Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.reps')} {item?.reps}X</p>
                        <p className="text-gray-600 mt-2">{item?.description}</p>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </Panel>
        </Collapse>
          {
            <div className="flex justify-center items-center gap-2 mt-2">
              <div className="w-full cursor-pointer bg-success-500 rounded-lg flex justify-center items-center gap-2 text-white font-semibold p-2" onClick={() => setModalVisible(true)} >
                <Plus />
                {t('Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.add')}
              </div>
              <div className="w-full cursor-pointer bg-primary-500 rounded-lg flex justify-center items-center gap-2 text-white font-semibold p-2" onClick={() => fetchOpenAiProgram()}>
                <MagicWand01 />
                Generate New
              </div>
            </div>
          }
          {
            isModalVisible && <AddProgramPopup
              onOk={() => {
                setModalVisible(false)
                fetchData(1)
                setRefresh(!refresh)
              }}
              refresh={refresh}
              setRefresh={setRefresh}
              isVisible={isModalVisible}
              onCancel={() => setModalVisible(false)}
              selectedExercises={selectedProgram?.exercises!}
              setSelectedExercises={updateSelectedProgramExercises}
              program={selectedProgram}
            />
          }
        </>
        : <div className="bg-gray-50 h-96 flex flex-col justify-center gap-2 items-center">

          {program.statusCode == 404
            ? <>
              <GrYoga className="w-14 h-14" />
              <p className="flex">{program.errorMessage}</p>
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  children={<>{t('Admin.data.managePatient.omniRom.startScan')} <ArrowRight /></>}
                  onClick={() => {
                    navigate("", {
                      state: {
                        tab: "startScan"
                      }
                    });
                  }}
                />
              </div>
            </>
            : <>
              <FaceSad color="stroke-primary-300" width={40} height={40} />
              <p className="flex">{program?.errorMessage}</p>
            </>}
        </div>}</>)
}
